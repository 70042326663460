<template>
    <div>
      <el-dialog
        v-model="isDialogVisible"
        title="设备设置"
        width="600px"
        @close='closeDialog'
        @open='openDialog'
        >
        <div style="margin-top: 10px;">
          <el-descriptions
              :model="deviceEditForm"
              direction="horizontal"
              :column="1"
              border
          >
            <el-descriptions-item label="设备编号" label-class-name="descriptions-item-label">
              {{ deviceEditForm.deviceMac }}
            </el-descriptions-item>
            <el-descriptions-item label="设备所属项目" label-class-name="descriptions-item-label">
              {{ deviceEditForm.projectName === null ? '未分配项目' : deviceEditForm.projectName }}
            </el-descriptions-item>
          </el-descriptions>
          <div style="margin-top: 20px;">
            <el-form label-width="150px" :model="deviceEditForm" ref="deviceEditFormRef">
              <el-form-item prop="deviceName" label="设备名称">
                <el-input type="text" v-model="deviceEditForm.deviceName"></el-input>
              </el-form-item>
              <el-form-item prop="volume" label="音量">
                <el-input-number v-model="deviceEditForm.volume" :min="1" :max="10" />
              </el-form-item>
              <el-form-item prop="audioLevel" label="音频档位设置">
                <el-input-number v-model="deviceEditForm.audioLevel" :min="0" :max="9" />
              </el-form-item>
              <el-form-item prop="audioFormat" label="设备通话模式">
                <el-radio-group v-model="deviceEditForm.audioFormat">
                    <el-radio :label="0">极速模式</el-radio>
                    <el-radio :label="1">顺畅模式</el-radio>
                    <el-radio :label="2">普通模式</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="partLimit" v-show="deviceEditForm.deviceType === 0" label="通话局数限制">
                <el-input-number v-model="deviceEditForm.partLimit" :min="1" :max="4" />
              </el-form-item>
              <el-form-item prop="appeasePlayState" label="是否播放安抚音频">
                <el-switch
                    class="appeasePlayStateSwitch"
                    active-text="播放"
                    inactive-text="关闭"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    v-model="deviceEditForm.appeasePlayState"
                />
                <el-text  v-show="deviceEditForm.deviceType === 1" style="margin-left: 10px;">（设置主机所属项目的所有设备）</el-text>
              </el-form-item>
              <el-form-item prop="deviceTitle" v-show="deviceEditForm.deviceType === 1" label="主机屏幕标题">
                <el-input type="text" v-model="deviceEditForm.deviceTitle"></el-input>
              </el-form-item>
              <el-form-item prop="deviceSubtitle" v-show="deviceEditForm.deviceType === 1" label="主机屏幕副标题">
                <el-input type="text" v-model="deviceEditForm.deviceSubTitle"></el-input>
              </el-form-item>
              <el-form-item prop="remark" label="备注">
                <el-input type="textarea" v-model="deviceEditForm.remark" row="3"></el-input>
              </el-form-item>
              <el-form-item style="margin-top: 20px;">
                <el-button type="primary" @click="doDeviceEdit">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import { reactive, computed } from 'vue';
    import { ElMessage } from 'element-plus';
    import request from '@/utils/interface.js';

    export default {
        name: 'DialogDeviceEdit',
        props: {
            deviceKey: {
                type: String,
                default: undefined
            },
            visible: {
                type: Boolean,
                default: false
            },
            reload: {
              type: Function,
              default: () => null
            }
        },
        setup(props, ctx) {
            const isDialogVisible = computed({
                get() {
                    return props.visible
                },
                set(val) {
                    return val
                }
            });

            const deviceEditForm = reactive({
                deviceName: '',
                deviceMac: '',
                deviceType: 0,
                volume: 0,
                audioLevel: 0,
                audioFormat: 0,
                deviceTitle: '',
                deviceSubtitle: '',
                remark: ''
            });

            const closeDialog = () => {
                ctx.emit('update:visible', false);
                props.reload();
            };

            const openDialog = () => {
              request.queryDeviceDetail(props.deviceKey).then(res => {
                    if (res.success) {
                        const device = res.data;
                        deviceEditForm.deviceKey = device.deviceKey;
                        deviceEditForm.deviceType = device.deviceType;
                        deviceEditForm.partLimit = device.partLimit;
                        deviceEditForm.deviceMac = device.deviceMac;
                        deviceEditForm.deviceName = device.deviceName;
                        deviceEditForm.agentKey = device.agentKey;
                        deviceEditForm.agentName = device.agentName;
                        deviceEditForm.projectName = device.projectName;
                        deviceEditForm.volume = device.volume;
                        deviceEditForm.audioLevel = device.audioLevel;
                        deviceEditForm.audioFormat = device.audioFormat;
                        deviceEditForm.deviceTitle = device.deviceTitle;
                        deviceEditForm.deviceSubTitle = device.deviceSubTitle;
                        deviceEditForm.appeasePlayState = device.deviceAppeasePlayState;
                        deviceEditForm.remark = device.remark;
                    } else {
                        ElMessage.error(res.message);
                    }
                }).catch(error => {
                    console.log(error)
                });
              // console.log('DialogDeviceEdit-openDialog', props.deviceKey, deviceEditForm);
            };

            const doDeviceEdit = () => {
              const updateParam = {
                deviceName: deviceEditForm.deviceName,
                volume: deviceEditForm.volume,
                audioLevel: deviceEditForm.audioLevel,
                audioFormat: deviceEditForm.audioFormat,
                partLimit: deviceEditForm.partLimit,
                deviceTitle: deviceEditForm.deviceTitle,
                deviceSubTitle: deviceEditForm.deviceSubTitle,
                appeasePlayState: deviceEditForm.appeasePlayState,
                remark: deviceEditForm.remark
              };
              request.updateDevice(props.deviceKey, updateParam).then(res => {
                if (res.success) {
                  ElMessage.success('设备更新成功');
                  ctx.emit('update:visible', false);
                } else {
                  ElMessage.error(res.message);
                }
              }).catch(error => {
                  console.log(error)
              });
              // console.log('DialogDeviceEdit-doDeviceEdit', props, deviceEditForm);
            }

            return {
              deviceEditForm,
              isDialogVisible,
              closeDialog,
              openDialog,
              doDeviceEdit
            };
        }
    };
</script>

<style>
.form-item-label {
    width: 150px;
}
.appeasePlayStateSwitch .el-switch__label {
    position: absolute;
    display: none;
    color: #000;
}
/*打开时文字位置设置*/
.appeasePlayStateSwitch .el-switch__label--right {
    z-index: 1;
    right: -10px;    /*不同场景下可能不同，自行调整*/
    color: #fff;
}
/*关闭时文字位置设置*/
.appeasePlayStateSwitch .el-switch__label--left {
    z-index: 1;
    left: 23px;    /*不同场景下可能不同，自行调整*/
    color: #fff;
}
/*显示文字*/
.appeasePlayStateSwitch .el-switch__label.is-active {
    display: block;
    color: #fff;
}
.appeasePlayStateSwitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
    width: 60px !important;    /*开关按钮的宽度大小*/
}
</style>
